<template>
    <v-container>
       
      <v-toolbar flat>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialogCrearAuditoria" max-width="800px">
          <template v-slot:activator="{ }">

            <v-tooltip style="width: 10%; margin-left: -10px" left color="success" class="me-2">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @mousedown.prevent="dialogCrearAuditoria = true" small class="ms-2" fab color="success" v-bind="attrs" v-on="on">
                  <v-icon> add </v-icon>
                </v-btn>
              </template>
              <span>Crear nueva auditoria</span>
            </v-tooltip>
            
            <div>
              <v-btn v-if="isButtonVisible" color="primary" @click="submitForm">Auditar prefactura </v-btn>
            </div>

          </template>
          <v-card>
            <v-tabs v-model="tipoCreacion" background-color="blue darken-4" dark class="v-tabs--fixed-tabs menu">
              <v-tabs-slider color="yellow" class="slider"></v-tabs-slider>
                <v-tab> Carga.csv prefactura </v-tab>
            </v-tabs>
          </v-card>
            <v-tabs-items v-model="tipoCreacion">
              <v-tab-item>
                <v-card-text>
                  <v-form>
                    <!-- Campo para subir el archivo plano -->
                    <div class="subirPlano mt-2" v-show="verInput">
                      <input class="input" type="file" accept=".csv" ref="archivoInput" @change="cargarArchivo" >
                        <p v-if="cargando" class="text-center mt-5">
                          {{ mensaje }}
                          <v-icon :class="{ 'rotate-animation': cargando }" large> rotate_right </v-icon>
                        </p>
                        <!-- Mensaje de archivo incorrecto -->
                        <p v-else-if="formatoIncorrecto" class="d-flex justify-center mt-6 error--text">
                          <v-icon class="mr-2" color="#ff5252">error</v-icon>
                          {{ mensaje }}
                        </p>
                        <p v-else class="d-flex text-center mt-3 justify-center mensaje">
                          {{ mensajeCampoCargaArchivo }}
                        </p>
                      </div>
                  </v-form>
                </v-card-text>
              </v-tab-item>  
            </v-tabs-items>
        </v-dialog>
      </v-toolbar>
  
      <!-- Tabla de Auditoría -->
      <!-- Tabla de Auditoría -->
      <v-card class="pa-4" style="max-height: 100vh; overflow-y: auto; overflow-x: hidden;">  
        <v-data-table
          :headers="headers"
          :items="crearAuditoriaArray"
          item-value="name"
          class="tableResults mt-0"
          height="59vh"
        >

          <!-- Acciones: Ver Detalle y Editar Factura (si existe) -->
          <template v-slot:item.actions="{ item }">                     
            <v-btn text icon color="blue" @click="abrirModalVerDetalleCargue(item)">
              <v-icon>description</v-icon>
            </v-btn>
            
            <!-- Botón de edición solo si ya tiene factura -->
            <v-btn v-if="item.factura" text icon color="green" @click="editarFactura(item)">
              <v-icon>note_add</v-icon>
            </v-btn>
          </template>

          <!-- Nueva Columna para Factura -->
          <template v-slot:item.factura="{ item }">
            <span v-if="item.factura">{{ item.factura }}</span>
            <v-btn v-else small color="primary" @click="abrirModalAsignarFactura(item)">
              Asignar Factura
            </v-btn>
          </template>

        </v-data-table>
      </v-card> 

       <!-- Modal para asignar o editar factura respectivamente-->
       <v-dialog v-model="modalFacturaVisible" max-width="600px">
          <v-card>
            <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
              <span class="headline">{{ esEdicion ? 'Editar Factura' : 'Asignar Factura' }} al cargue {{ idCargueSeleccionado }}</span>
            </v-toolbar>
            <v-card-text class="mt-4">
              <v-text-field
                v-model="facturaInput"
                label="Ingrese número de factura"
                outlined
              />
            </v-card-text>
            <v-card-actions>
              <v-btn color="green" @click="guardarFactura">Guardar</v-btn>
              <v-btn color="gray" @click="cerrarModal">Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      <!-- Modal para ver el detalle del cargue de la prefactura -->
      <v-dialog v-model="dialogVerDetalleCargue" max-width="1200px">
        <v-card>
          <v-toolbar style="position: sticky; top: 0; z-index: 1;" color="primary" class="text-h6" dark>
            <span>Detalle del Cargue (ID: {{ idCargueSeleccionado2 }})</span>
          </v-toolbar> 
          <v-card-text class="mt-4">
              <v-simple-table class="tableResults mt-0" height="59vh" style="margin-top: 20px;">
                <thead>
                  <tr>
                    <th>ID Prefactura</th>
                    <th>Número Guía</th>
                    <th>Remitente</th>
                    <th>Centro de Costos</th>
                    <th>Fecha Orden</th>
                    <th>Ciudad Origen</th>
                    <th>Ciudad Destino</th>
                    <th>Servicio</th>
                    <th>Peso (kg)</th>
                    <th>Piezas</th>
                    <th>Valor Total</th>
                    <th>Gestión</th>
                    <th>Motivo</th>
                    <th>Soporte</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(detalle, index) in detallesCargue" :key="index">
                    <td>{{ detalle.idPrefactura }}</td>
                    <td>{{ detalle.numeroGuia }}</td>
                    <td>{{ detalle.remitente }}</td>
                    <td>{{ detalle.centroCostos }}</td>
                    <td>{{ new Date(detalle.fechaOrden).toLocaleDateString() }}</td>
                    <td>{{ detalle.ciudadOrigen }}</td>
                    <td>{{ detalle.ciudadDestino }}</td>
                    <td>{{ detalle.servicio }}</td>
                    <td>{{ detalle.peso }}</td>
                    <td>{{ detalle.piezas }}</td>
                    <td>${{ detalle.valorTotal.toLocaleString() }}</td>
                    <td>{{ detalle.gestion }}</td>
                    <td>{{ detalle.motivo }}</td>
                    <td>{{ detalle.soporte }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogVerDetalleCargue = false">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>

  
      <!-- Modal para mostrar observaciones -->
      <!-- <v-dialog v-model="dialogVerObservaciones" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Observaciones</span>
          </v-card-title>
          <v-card-text>
            <v-simple-table class="elevation-1">
              <thead>
                <tr>
                  <th>Archivo Subido</th>
                  <th>Observación</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(observacion, index) in observaciones" :key="index">
                  <td>{{ observacion.archivo }}</td>
                  <td>{{ observacion.texto }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogVerObservaciones = false">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
  
      <!-- Modal para cerrar auditoría -->
      <!-- <v-dialog v-model="dialogCerrarAuditoria" max-width="850px">
        <v-card>
          <v-card-title>
            <span class="headline">Cerrar Auditoría</span>
          </v-card-title>
          <v-card-text>
            <v-form>
                <v-row>
                    <v-col cols="12" md="3">
                        <v-autocomplete
                            v-model="tipoEstancia"
                            :items="tipoEstanciaOptions"
                            label="Tipo de estancia"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="Fecha egreso de ips/ese"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field disabled label="Días hospitalización"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field disabled label="Mes de egreso"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-autocomplete
                            v-model="diasInoportunidad"
                            :items="diasInoportunidadOptions"
                            label="Días inoportunidad"
                            clearable
                        ></v-autocomplete>
                    </v-col>       
                    <v-col cols="12" md="3">
                        <v-text-field label="Motivo inoportunidad"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-autocomplete
                            v-model="condicionEgreso"
                            :items="condicionEgresoOptions"
                            label="Condición egreso del afiliado"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-autocomplete
                            v-model="destinoAfiliado"
                            :items="destinoAfiliadoOptions"
                            label="Destino del afiliado"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="Sitio de remisión"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-autocomplete
                        v-model="facturaPatologia"
                        :items="['Sí', 'No']"
                        label="Factura susceptible patología base"
                        clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="Motivo hospitalización patología base"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="Motivo por el cual a pesar de la descompensación no está a cargo de la UT"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="Valor hospitalizacion a cargo de la UT"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="Reporte evento adverso"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-autocomplete
                        v-model="infeccionCuidadoSalud"
                        :items="['Sí', 'No']"
                        label="Infecciones relacionadas al cuidado de la salud"
                        clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="Tasa de mortalidad intrahospitalaria"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-autocomplete
                            v-model="pacientePolimedicado"
                            :items="pacientePolimedicadoOptions"
                            label="Paciente polimedicado"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-autocomplete
                            v-model="conciliacionMedicamentosa"
                            :items="conciliacionMedicamentosaOptions"
                            label="Conciliación medicamentosa"
                            clearable
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field label="Fecha cita post hospitalaria"></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogCerrarAuditoria = false">Cancelar</v-btn>
            <v-btn color="red darken-1" text @click="cerrarAuditoria">Cerrar Auditoría</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
  
      <!-- Modal para agregar observación -->
      <!-- <v-dialog v-model="dialogCrearObservacion" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Agregar Observación</span>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-textarea label="Seguimiento a la auditoría" v-model="observacion" required></v-textarea>
              <v-file-input label="Historia clínica" v-model="historiaClinica" required></v-file-input>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogCrearObservacion = false">Cancelar</v-btn>
            <v-btn color="blue darken-1" text @click="guardarObservacion">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

      <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor" top>
        {{ snackbarMessage }}
      </v-snackbar> 
 
       <!-- Spinner visible cuando loading es true -->
      <div v-if="loading" class="spinner-container">
        <div class="spinner"></div>
        <p>Procesando la auditoria sobre las guias de la prefactura cargada...</p>
      </div>

    </v-container>
  </template>
  
  <script>
  import Papa from 'papaparse';

  export default {
    data() {
      return {
        
        // Cargar excel:
        crearAuditoriaArray: [],
        modalFacturaVisible: false, // Estado del modal
        facturaInput: "", // Número de factura ingresado
        esEdicion: false, // Indica si estamos en modo edición
        idCargueSeleccionado: null, // ID del cargue seleccionado
        headers: [
          { text: 'Id Cargue Prefactura',      value: 'idPrefactura'     , width: '170px'  },
          { text: 'Fecha corte',               value: 'fechaCorte'       , width: '170px'  },
          { text: 'Guías válidas',             value: 'registrosValidos' , width: '130px'  },
          { text: 'Guías con inconsistencias', value: 'inconsistencias'  , width: '170px'  },
          { text: 'Usuario cargue',            value: 'reporteInconsistencias', width: '170px'  },
          { text: 'Factura',                   value: 'factura'                },
          { text: "Detalle Cargue Prefactura",                value: "actions", sortable: false},  // descomentar este muestra botones de acciones
        ],
        dialogCrearAuditoria: false,
        tipoCreacion: 0,          //  tabs y v-tabs-items                                      v-model 
        verInput: true,           //  v-tabs-items > v-tab-item > v-card-text > v-form > div > v-show
        mensajeCampoCargaArchivo: 'Arrastre un archivo .CSV con la prefactura. \nTambién puede hacer click en este cuadro.',
        formatoIncorrecto: false, // valida formato de cargue
        cargando: false,          // icono cargando
        cantidadRegistros: null,  // almacena cantidad de registros cargados del .csv de la prefactura
        // cargarArchivo
        // procesarArchivo (method)
        registros: [],            // array que carga el excel desde el input
        // validar campos requeridos: 
        // Guia [0], Centro de costos [3], ciudad origen [7], ciudad destino [8], Servicio [10], Tarifa [14]
        snackbar: false,
        snackbarMessage: '',
        snackbarColor: 'error',
        timeout: 10000,

        loading: false, // Asegurar que existe

        // auditar array registros
        isButtonVisible: false,
        formData: new FormData(),

        // modal para ver detalle del cargue:
        dialogVerDetalleCargue: false,
        idCargueSeleccionado2: null, // ID del cargue que se seleccionó
        detallesCargue: [], // Detalles del cargue de la prefactura obtenidas desde el backend

        /*dialogCrearObservacion: false,
        dialogVerObservaciones: false,
        dialogCerrarAuditoria: false,
        observaciones: [],
        observacion: '',
        historiaClinica: null,

        ipsList: [],
        ipsSeleccionada: null,
        cargandoIps: false,

        perteneceCohorte: '',
        opcionesCohorte: ['Sí', 'No'],

        diagnosticoRiesgo: '',
        opcionesDiagnostico: [ 'Hipertensión' , 'Diabetes',  'EPOC', 'No'],

        tipoIngreso: '',
        opcionesTipoIngreso: ['Espontáneo', 'Remisión', 'Rescate'],

        inoportunidadRescate: '',
        opcionesSiNo: ['Sí', 'No'],

        reingresoHospitalizacion: '',
        opcionesSiNoHospitalizacion: ['Sí', 'No'],

        tipoEstancia: '',
        tipoEstanciaOptions: ["Hospitalaria", "Quirúrgica", "Urgencias"],

        diasInoportunidad: '',
        diasInoportunidadOptions: Array.from({ length: 20 }, (_, i) => i + 1),

        destinoAfiliado: '',
        destinoAfiliadoOptions: [
            'Remisión',
            'Contraremisión',
            'Domiciliaria',
            'Fallecido < 48 horas',
            'Fallecido > 48 horas',
            'Muerte perinatal'
        ],

        condicionEgreso: '',
        condicionEgresoOptions: ['Vivo', 'Muerto'],

        pacientePolimedicado: '',
        pacientePolimedicadoOptions: ['Sí', 'No'],

        conciliacionMedicamentosa: '',
        conciliacionMedicamentosaOptions: ['Sí', 'No'] */


      };
    },
    
    mounted() {

      this.getListaCargueAuditoria()

    },


    methods: {

      abrirModalCrearAuditoria() {
        this.dialogCrearAuditoria = true;
      },

      // Método cuando que se hace una carga de .csv prefactura      
      /**
      * Método para cargar un archivo CSV y procesar su contenido.
      * @param {*} event - El evento del cambio de archivo.
      */
      cargarArchivo(event) {
        this.registros = [];
        this.formatoIncorrecto = false;
        const file = event.target.files[0];
        // Verificar si se seleccionó un archivo y si su extensión es CSV.
        if (!file || file.name.split('.').pop() !== 'csv') {
          this.formatoIncorrecto = true;
          this.mensaje = 'Formato incorrecto, debe subir o arrastrar un archivo .csv.';
        } else {
          // Leer el contenido del archivo.
          const reader = new FileReader();
          reader.onload = (e) => {
            // Parsear el contenido CSV.
            const content = e.target.result;
            this.procesarArchivo(content);
          };
          reader.readAsText(file);
          this.formatoIncorrecto = false;
        } 
      },

      /**
      * Procesa el contenido de un archivo CSV.
      * @param {*} content El contenido del archivo CSV.
      */
      async procesarArchivo(content) {
        const parsedData = Papa.parse(content, {
          header: false,
          delimiter: ',',
        });
          
        this.cargando = true;
        this.mensaje = 'Validando la información del archivo, espere un momento...';

        // Verificar si la primera fila es un encabezado válido
        const encabezado = parsedData.data[0];
        // Validar que la primera fila tenga 21 columnas con nombres de texto alfabético
        const esEncabezado = encabezado.length === 21 && encabezado.every((celda) => /^[a-zA-Z\s]+$/.test(celda.trim()));
        // Definir los nombres de los campos requeridos
        const nombresCamposRequeridos = [
          "Guía",             // [0]
          "Centro de costos", // [3]
          "Ciudad origen",    // [7]
          "Ciudad destino",   // [8]
          "Servicio",         // [10]
          "Tarifa"            // [14]
        ];

        const errores = []; // Arreglo para acumular los errores

        // Iterar sobre las filas de datos.
        for (let index = esEncabezado ? 1 : 0; index < parsedData.data.length - 1; index++) {
          const row = parsedData.data[index];
          // Verificar si la fila está vacía
          if (row.every(cell => !cell || cell.trim() === '')) {
            continue; // Saltar filas vacías
          }
          // Indices de los campos requeridos
          const camposRequeridos = [0, 3, 7, 8, 10, 14];
          // Filtrar campos vacíos
          const camposVacios = camposRequeridos.filter((campoIndex) => !row[campoIndex] || row[campoIndex].trim() === '');
          if (camposVacios.length > 0) {
            // Mapear los nombres de los campos vacíos usando los índices
            const nombresCamposVacios = camposVacios.map((campoIndex) => nombresCamposRequeridos[camposRequeridos.indexOf(campoIndex)]);
            // Crear el mensaje de error con los nombres de los campos
            const mensajeError = `Fila ${index + 1}: Los siguientes campos están vacíos: ${nombresCamposVacios.join(', ')}`;
            console.error(mensajeError); // Mostrar el error en la consola
            errores.push(mensajeError); // Agregar el error al arreglo de errores
            continue; // Saltar al siguiente registro
          }

          if (errores.length > 0) {
            this.showError(errores.join('\n'));
            this.dialogCrearAuditoria = false; // Cierra el diálogo
            // Resetear el valor del input de archivo
            if (this.$refs.archivoInput) {
                this.$refs.archivoInput.value = ''; // Resetea el input de archivo
            }
            this.cargando = false;
            this.mensaje = "";
            this.registros = [];
          } else {
            // Si todos los campos requeridos están llenos, agregar la fila completa (21 columnas) al arreglo
            this.registros.push({
              // Mapear las 21 columnas como un objeto dinámico
              ...row.reduce((obj, value, colIndex) => {
                obj[`columna_${colIndex + 1}`] = value ? value.trim() : ''; // Usar nombres dinámicos para las columnas
                return obj;
              }, {})
            });
            this.showSuccess(`Archivo cargado con éxito, se van a procesar ${this.registros.length} registros`);
            this.dialogCrearAuditoria = false; // Cierra el diálogo
            this.cargando = false;
            this.mensaje = ""; 
          }

        }
            
        if (this.registros.length > 0) {
                
          this.cantidadRegistros = this.registros.length;
          console.log(this.registros.length)
          console.log(this.registros)
                
          // Enviamos el array validado al back para auditar los datos
          this.isButtonVisible = true;     
          

        } else {
          this.mensaje = 'El archivo no tiene registros. \nIntente con otro archivo nuevamente.'
        }
      },

      /**
      * Muestra un mensaje de éxito en un snackbar: this.showSuccess('Observación guardada correctamente');
      * 
      * Este método configura y muestra un snackbar con un mensaje de éxito específico,
      * cambiando el color del snackbar a verde para indicar una operación exitosa.
      *
      * @param {string} message - El mensaje de éxito a mostrar en el snackbar.
      */
      showSuccess(message) {
        this.snackbarMessage = message;
        this.snackbarColor = 'success';
        this.snackbar = true;
      },

      /**
      * Muestra un mensaje de error en un snackbar: this.showError("El campo 'Tipo de Estancia' es obligatorio.");
      * 
      * Este método configura y muestra un snackbar con un mensaje de error específico,
      * cambiando el color del snackbar a rojo para indicar que ha ocurrido un error.
      *
      * @param {string} message - El mensaje de error a mostrar en el snackbar.
      */
      showError(message) {
        this.snackbarMessage = message;
        this.snackbarColor = 'error';
        this.snackbar = true;
      },

      /**
      * Envía el formulario de carga de archivos RIPS.
      * 
      * Este método prepara y envía los datos del formulario, incluyendo el encabezado y los archivos seleccionados,
      * a un servidor mediante una solicitud HTTP POST. También maneja los casos de éxito y error al enviar los datos.
      */
      async submitForm() {

        const registrosDTO = this.registros
        .filter(row => !isNaN(row.columna_1)) // Filtra filas donde columna_1 no sea numérica
        .map(row => {
          const fechaOrden  = this.parseFecha(row.columna_2); 
          const fechaSalida = this.parseFecha(row.columna_6);
          return {
            numeroGuia:     parseInt(row.columna_1, 10),
            fechaOrden:     fechaOrden ? fechaOrden.toISOString().split('T')[0] : null,
            remitente:      row.columna_3,
            centroCostos:   row.columna_4,
            mes:            parseInt(row.columna_5, 10),
            fechaSalida:    fechaSalida ? fechaSalida.toISOString().split('T')[0] : null,
            nombreCliente:  row.columna_7,
            ciudadOrigen:   parseInt(row.columna_8, 10),
            ciudadDestino:  parseInt(row.columna_9, 10),
            departamento:   parseInt(row.columna_10, 10),
            servicio:       row.columna_11,
            peso:           parseFloat(row.columna_12),
            piezas:         parseInt(row.columna_13, 10),
            valorDeclarado: parseFloat(row.columna_14),
            tarifa:         parseFloat(row.columna_15),
            flete:          parseFloat(row.columna_16),
            costoManejo:    parseFloat(row.columna_17),
            valorTotal:     parseFloat(row.columna_18),
            gestion:        row.columna_19,
            motivo:         row.columna_20,
            procesoSAC:     row.columna_21,
            contiene:       row.columna_22,
          };
        });

        let obj = JSON.stringify(registrosDTO);
        this.formData.append('prefactura', new Blob([obj], { type: 'application/json' }));
        // Imprimir el contenido de FormData
        for (let [key, value] of this.formData.entries()) {
          console.log(key, value);
        }
        console.log(obj)
        
        // recibe .csv del método generarCsv del back
       /*this.$http.post( "msa-logistics/api/v1/auditoria/transporte/guardar-informacion", this.formData ).then((response) => {
          // Crear un objeto Blob con los datos del CSV
          const blob = new Blob([response.data], { type: "text/csv" });
          // Crear un enlace de descarga
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "prefactura.csv"; // Nombre del archivo
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.showSuccess("Auditoria de la prefactura completada");
          this.loading = false;
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }).catch((error) => {
          this.loading = false;
          // Extraer y mostrar el mensaje de error específico
          let errorMessage = "Ocurrió un error inesperado al enviar la prefactura";
          if (error.response && error.response.data && error.response.data.message) {
            if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join(', ');
            } else {
              errorMessage = error.response.data.message;
            }
          }
          this.showError(errorMessage);
        }) */

        // recibe .zip a través del método back generarArchivoZip
        /*this.$http.post(
          "msa-logistics/api/v1/auditoria/transporte/guardar-informacion", 
          this.formData,
          { responseType: "blob" } // Importante para recibir binarios como ZIP
        ).then((response) => {
          // Crear un objeto Blob con los datos del ZIP
          const blob = new Blob([response.data], { type: "application/zip" });

          // Crear un enlace de descarga
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "prefacturas.zip"; // Nombre del archivo ZIP
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.showSuccess("Auditoría de la prefactura completada");

          this.loading = false;
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        }).catch((error) => {
          this.loading = false;
          
          // Extraer y mostrar el mensaje de error específico
          let errorMessage = "Ocurrió un error inesperado al enviar la prefactura";
          if (error.response && error.response.data && error.response.data.message) {
            if (Array.isArray(error.response.data.message)) {
              errorMessage = error.response.data.message.join(', ');
            } else {
              errorMessage = error.response.data.message;
            }
          }
          this.showError(errorMessage);
        }); */
     
        this.loading = true;
        this.$http.post(
          "msa-logistics/api/v1/auditoria/transporte/guardar-informacion", 
          this.formData
        ).then((response) => {
          const { validos, inconsistentes, archivoZip, mensaje } = response.data;

          // Decodificar Base64 a binario
          const byteCharacters = atob(archivoZip);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "application/zip" });

          // Crear enlace de descarga
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "prefacturas.zip"; // Nombre del archivo
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.loading = false;

          // Mostrar mensaje con información sobre los registros procesados
          if (validos === 0) {
            this.showError(`No se generó un cargue porque no hay registros válidos. (Válidos: ${validos}, Inválidos: ${inconsistentes})`, { duration: 10000 });
          } else {
            this.showSuccess(`${mensaje} (Válidos: ${validos}, Inválidos: ${inconsistentes})`, { duration: 10000 });
          }
          
          setTimeout(() => {
            window.location.reload();
          }, 10000);
        }).catch((error) => {
          this.loading = false;

          let errorMessage = "Ocurrió un error inesperado al enviar la prefactura";
          if (error.response && error.response.data && error.response.data.mensaje) {
            errorMessage = error.response.data.mensaje;
          }
          this.showError(errorMessage);
        });


      },

      /*
        Convierte "DD/MM/YYYY" proveniente del .CSV a Date válido para el Json DTO del back 
      */
      parseFecha(fechaStr){
        if (!fechaStr || typeof fechaStr !== 'string') return null;
        const partes = fechaStr.split('/');
        if (partes.length !== 3) return null;
        const [dia, mes, anio] = partes.map(Number);
        if (isNaN(dia) || isNaN(mes) || isNaN(anio)) return null;
        return new Date(`${anio}-${mes}-${dia}`);
      },

      getListaCargueAuditoria() {
        // Realizar la solicitud GET para obtener los datos de la API
        this.$http
          .get('msa-logistics/api/v1/auditoria/transporte/getAllCargueAuditoriaTransporte')
          .then((response) => {
            console.log('Datos recibidos:', response.data);

            // Transformar los datos a la estructura esperada
            this.crearAuditoriaArray = response.data.map(item => ({
              idPrefactura: item.idCargue,              
              fechaCorte: item.createDate,              
              registrosValidos: item.registrosValidos,  
              inconsistencias: item.registrosInconsistentes, 
              prefacturaValida: "Si",         
              reporteInconsistencias: item.lastmodifiedby, 
              factura: item.numeroFactura               
            }));

            console.log('Datos transformados:', this.crearAuditoriaArray);
          })
          .catch((error) => {
            console.error('Error al obtener los datos:', error);
          });
      },

      abrirModalAsignarFactura(item) {  
        this.idCargueSeleccionado = item.idPrefactura;
        this.facturaInput = "";
        this.esEdicion = false; // Modo asignar
        this.modalFacturaVisible = true;
      },

      editarFactura(item) {
        this.idCargueSeleccionado = item.idPrefactura;
        this.facturaInput = item.factura; // Cargar factura existente
        this.esEdicion = true; // Modo edición
        this.modalFacturaVisible = true;
      },

      cerrarModal() {
        this.modalFacturaVisible = false;
        this.idCargueSeleccionado = null;
        this.facturaInput = "";
      },

      // Método que relaciona un idCargue es decir un registro de la tabla con un número de facturación
      guardarFactura() {
        if (!this.facturaInput.trim()) {
          alert("Debe ingresar un número de factura válido");
          return;
        }

        // Llamada a la API para asignar factura
        this.$http.put(`msa-logistics/api/v1/auditoria/transporte/asignar-factura/${this.idCargueSeleccionado}`, {
          numeroFactura: this.facturaInput.trim()  // ✅ Enviar como String
        })
        .then(() => {
          // Actualizar la tabla con el número de factura
          const item = this.crearAuditoriaArray.find(i => i.idPrefactura === this.idCargueSeleccionado);
          if (item) {
            item.factura = this.facturaInput;
          }

          // Mostrar mensaje de éxito personalizado
          if (this.esEdicion) {
            this.showSuccess(`Factura relacionada al ID de cargue ${this.idCargueSeleccionado} actualizada.`);
          } else {
            this.showSuccess(`Factura relacionada al ID de cargue ${this.idCargueSeleccionado}.`);
          }

          this.cerrarModal();
        })
        .catch(error => {
          console.error("Error al asignar la factura:", error);
        });
      },

      // Método para abrir el modal y cargar las observaciones
      abrirModalVerDetalleCargue(item) {
        this.idCargueSeleccionado2 = item.idPrefactura; // ID del cargue
        this.obtenerDetalleCargue(item.idPrefactura); // Cargar observaciones
        this.dialogVerDetalleCargue = true; // Abrir el modal
      },

      // Método para obtener observaciones del backend
      obtenerDetalleCargue(idCargue) {
        this.$http.get(`msa-logistics/api/v1/auditoria/transporte/obtenerDetalleCargue/${idCargue}`)
          .then(response => {
            this.detallesCargue = response.data.map(item => ({
              idPrefactura: item.idPrefactura,
              numeroGuia: item.numeroGuia,
              remitente: item.remitente,
              centroCostos: item.centroCostos,
              fechaOrden: item.fechaOrden,
              ciudadOrigen: item.ciudadOrigen.ciudad,
              ciudadDestino: item.ciudadDestino.ciudad,
              servicio: item.servicio,
              peso: item.peso,
              piezas: item.piezas,
              valorTotal: item.valorTotal,
              gestion: item.gestion,
              motivo: item.motivo,
              soporte: item.soporte ? "Sí" : "No"
            }));
            console.log("detalles cargue:  ----------------" + this.detallesCargue);
          })
          .catch(error => {
            console.error("Error al obtener detalles del cargue:", error);
          });
      }


    },
  };
  </script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->

<style scoped>

::v-deep .v-toolbar__content {
    justify-content: space-between !important;
  }

  ::v-deep .tableResults div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
  }

  ::v-deep .tableResults div table thead tr th span {
    font-weight: bold;
    color: black !important;
  }

  th {
    color: black !important;
  }

  ::v-deep .tableResults div {
    --sb-track-color: #d1cfcf;
    --sb-thumb-color: #8b8585;
    --sb-size: 8px;
  }

  ::v-deep .tableResults div::-webkit-scrollbar {
    width: var(--sb-size)
  }

  ::v-deep .v-messages {
    display: none;
  }
  ::v-deep .v-text-field__details {
    display: none;
  }

  ::v-deep .tableResults div::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 6px;
  }

  ::v-deep .tableResults div::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 6px;
  }

  ::v-deep .v-input__prepend-outer {
    display: none;
  }

  ::v-deep .v-input__icon--prepend {
    display: none;
  }

  .color-red {
    color: red; /* Define el color rojo */
  }

  .v-card-text{
    margin-top: 20px
  }

.encabezado {
    background-color: #1867c0;
    color: white;
}
::v-deep .elevation .v-data-footer {
    width: 100%;
}
::v-deep .elevation .v-data-footer .v-data-footer__select{
    height: 2rem;
}
::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}
::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
::v-deep .csv td {
    max-width: 28rem !important;
}
::v-deep .csv th {
    color: black !important;
}
::v-deep .trazabilidad .v-data-footer__select {
    display: none;
}
::v-deep .trazabilidad .v-data-footer {
    justify-content: end;
}
::v-deep .v-tabs-slider-wrapper {
    top: 88% !important;
    margin-left: 16px !important;
}
.slider {
    width: 326px !important;
}
.subirPlano {
    outline: 2px dashed grey;
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 1rem;
    height: 100px;
    position: relative;
    cursor: pointer;
}
.input {
    opacity: 0;
    width: 100%;
    height: 100px;
    position: absolute;
    cursor: pointer;
}
.rotate-animation {
    animation: rotate 2s linear infinite;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.checkbox {
    width: 8rem;
}
.norma {
    font-size: 14.5px;
    width: 93%;
}
.fila {
    width: 50%;
}
.encabezado th {
    min-width: 8rem;
    max-width: 22rem;
}
.mensaje {
    white-space: pre-line;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 33, 33, 0.46);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;
}
.alerta {
  position: fixed;
  top: 50% !important;
  left: 50%;
  transform: translateX(-50%);
  background-color: #5baa5e;
  color: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 15vw;
}

/* Contenedor centrado */
.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

/* Animación del spinner */
.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-top-color: #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Keyframes para la animación */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Texto debajo del spinner */
.spinner-container p {
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}
</style>
  